<template>
  <a-input
    :value="value[showLabel]"
    allow-clear
    readonly
    v-bind="$attrs"
    @click="handleClick"
  >
    <template #suffix>
      <select-outlined @click="handleClick" />
    </template>
  </a-input>
  <a-modal
    v-model:visible="visible"
    :mask-closable="false"
    v-bind="modal"
    @ok="handleOk"
  >
    <search-form
      v-if="items.length"
      :items="items"
      @fnSearch="handleFnSearch"
    />
    <grid
      ref="gridRef"
      :allow-selection="{ type: 'radio' }"
      :columns="columns"
      :code="code"
      :url="url"
      :search="search"
      :scroll="{ y: 400 }"
    >
      <template v-for="(index, name) in $slots" v-slot:[name]="slotProps">
        <slot :name="name" v-bind="slotProps" />
      </template>
    </grid>
  </a-modal>
</template>

<script>
import { /*getCurrentInstance,*/ reactive, ref, toRefs } from 'vue';
// import { Table, Transfer } from 'ant-design-vue';
import { SelectOutlined } from '@ant-design/icons-vue';
import SearchForm from '@/components/searchForm';
import Grid from '@/components/grid';

export default {
  name: 'SelectModal',
  components: {
    SelectOutlined,
    SearchForm,
    Grid,
  },
  props: {
    value: { type: Object, default: () => ({}) },
    showLabel: String,
    items: { type: Array, default: () => [] },
    columns: { type: Array, default: () => [] },
    modal: { type: Object, default: () => ({}) },
    code: String,
    url: { type: String, required: true },
  },
  setup(props, context) {
    // const { proxy } = getCurrentInstance();
    const gridRef = ref();
    const state = reactive({
      visible: false,
      search: {},
    });

    return {
      gridRef,
      ...toRefs(state),
      handleClick: () => {
        state.visible = true;
      },
      handleFnSearch: value => {
        state.search = value;
      },
      handleOk: () => {
        console.log(gridRef.value);
        context.emit('update:value', gridRef.value.selectedRows[0]);
        state.visible = false;
      },
    };
  },
};
</script>
