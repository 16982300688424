<template>
  <search-form :items="items" :form="search" @fnSearch="handleFnSearch" />
  <grid
    :columns="columns"
    :code="$route.path"
    :url="url"
    :search="search"
    :btn-operation="btnOperation"
    :scroll="{ y: gridHeight }"
  >
    <template #operation="{ record }">
      <operation :options="options" :record="record" />
    </template>
  </grid>
  <a-modal
    v-model:visible="visible"
    :title="form.id ? '编辑' : '新增'"
    :maskClosable="false"
    :confirm-loading="confirmLoading"
    @ok="handleOk"
  >
    <a-form
      ref="formRef"
      :model="form"
      :rules="rules"
      :label-col="{ span: 6 }"
      :wrapper-col="{ span: 15 }"
      :validate-trigger="['change', 'blur']"
    >
      <a-form-item name="goodId" label="礼品">
        <select-modal
          v-model:value="form.goodId"
          placeholder="请选择礼品"
          :modal="{ title: '礼品', width: 700 }"
          :items="[{ key: 'name', label: '名称' }]"
          showLabel="name"
          code="addr:add"
          :url="giftUrl"
          :columns="giftColumns"
        >
          <template #image="{ text }">
            <a-tooltip title="点击预览">
              <div class="iss-image"><a-image :src="text" /></div>
            </a-tooltip>
          </template>
        </select-modal>
      </a-form-item>
      <a-form-item name="exchange" label="兑换地点">
        <a-input v-model:value="form.exchange" placeholder="请输入兑换地点" />
      </a-form-item>
      <a-form-item name="addr" label="详细地址">
        <a-textarea v-model:value="form.addr" placeholder="请输入详细地址" />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { Form, Image, Tooltip } from 'ant-design-vue';
import SearchForm from '@/components/searchForm';
import Grid from '@/components/grid';
import Operation from '@/components/operation';
import SelectModal from '@/components/selectModal';
import integralApi from '@/api/integral';

export default {
  components: {
    AForm: Form,
    AFormItem: Form.Item,
    AImage: Image,
    ATooltip: Tooltip,
    SearchForm,
    Grid,
    Operation,
    SelectModal,
  },
  data() {
    const required = { required: true, message: '不能为空' };
    return {
      items: [
        { key: 'exchange', label: '兑换地点' },
        { key: 'addr', label: '详细地址' },
      ],
      columns: [
        { dataIndex: 'goodId', title: '礼品ID' },
        { dataIndex: 'exchange', title: '兑换地点' },
        { dataIndex: 'addr', title: '详细地址' },
        { dataIndex: 'updateTime', title: '更新时间' },
        {
          dataIndex: 'id',
          title: '操作',
          fixed: 'right',
          width: 90,
          slots: { customRender: 'operation' },
        },
      ],
      btnOperation: [
        { type: 'add', permission: 'addr:add', fnClick: this.handleEdit },
      ],
      options: [
        {
          type: 'update',
          permission: 'addr:update',
          fnClick: this.handleEdit,
        },
        {
          type: 'delete',
          permission: 'addr:delete',
          fnClick: this.handleEdit,
        },
      ],
      giftColumns: [
        {
          dataIndex: 'image',
          title: '图片',
          width: 80,
          slots: { customRender: 'image' },
        },
        { dataIndex: 'name', title: '名称' },
        { dataIndex: 'description', title: '描述' },
      ],
      url: integralApi.addrPageUrl,
      giftUrl: integralApi.giftPageUrl,
      gridHeight: document.body.clientHeight - 424,
      search: {},
      visible: false,
      confirmLoading: false,
      form: {},
      rules: {
        goodId: required,
        exchange: required,
        addr: required,
      },
      loading: false,
      dialogLoading: false,
      goodsListDialog: false,
      goodsListloading: false,
      goodsIdListParams: {
        exchWay: '1',
        current: 1,
        size: 10,
        total: 0,
        name: '',
        code: '',
      },
      goodsIdList: [],
    };
  },
  watch: {
    goodsListDialog(newVal) {
      if (newVal) return;
      this.resetGoodsForm();
    },
    centerDialogVisible(newValue) {
      if (newValue) return;
      this.editParams = {
        goodId: '',
        exchange: '',
        addr: '',
      };
    },
  },
  mounted() {
    this.getGoodsIdList(true);
  },
  methods: {
    handleFnSearch(value) {
      this.search = value;
    },
    handleEdit(item) {
      this.visible = true;
      this.form = item.id
        ? { ...item }
        : { goodId: '', exchange: '', addr: '' };
    },
    resetGoodsForm() {
      this.goodsIdListParams = {
        exchWay: '1',
        current: 1,
        size: 10,
        total: 0,
        name: '',
        code: '',
      };
      this.getGoodsIdList();
    },

    selectGoodsId(row) {
      this.editParams.goodId = row.id;
      this.resetGoodsForm();
      this.goodsListDialog = false;
    },

    // 获取礼品ID列表
    async getGoodsIdList(resetCurrent) {
      this.goodsListloading = true;
      if (resetCurrent) this.goodsIdListParams.current = 1;
      const res = await integralApi.getGoodsIdList(this.goodsIdListParams);
      this.goodsListloading = false;
      if (!res) return;
      this.goodsIdList = res.records;
    },

    handleSizeChange(val) {
      this.size = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.current = val;
      this.getList();
    },

    async getList() {},

    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.getList();
    },

    async showEditLog(logData = { isAdd: true }) {
      Object.assign(this.editParams, logData);
      this.centerDialogVisible = true;
    },

    async editLog() {
      const { isAdd } = this.editParams;
      const methodType = isAdd ? 'addExchangeAddr' : 'editExchangeAddr';
      const msgType = isAdd ? '新增' : '修改';
      this.dialogTitle = isAdd ? '新增兑换地点信息' : '修改兑换地点信息';

      this.dialogLoading = true;
      const res = await integralApi[methodType](this.editParams);
      this.dialogLoading = false;
      if (!res) return;
      this.$message({
        message: msgType + '成功！',
        type: 'success',
      });

      // 关闭对话框  刷新列表
      this.centerDialogVisible = false;
      this.getList(true);
    },

    async delExchangeAddr({ id }) {
      this.loading = true;
      const res = await integralApi.delExchangeAddr({ ids: [id] });
      this.loading = false;
      if (!res) return;
      this.getList(true);
      this.$message.success('删除成功！');
    },

    editCancel() {
      this.centerDialogVisible = false;
    },
  },
};
</script>
<style lang="less" scoped>
.iss-image {
  cursor: pointer;
}
</style>
